import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get(path: string, headers?: any, queryParams?: any): Observable<any> {
    return this.http.get(path, { headers: headers || null, params: queryParams }).pipe(retry(0));
  }

  post(path: string, headers?: any, queryParams?: any, body?: any): Observable<any> {
    return this.http.post(path, body, { headers: headers || null, params: queryParams }).pipe(retry(0));
  }

  put(path: string, headers?: any, queryParams?: any, body?: any): Observable<any> {
    return this.http.put(path, body, { headers: headers || null, params: queryParams }).pipe(retry(0));
  }

  delete(path: string, headers?: any, queryParams?: any): Observable<any> {
    return this.http.delete(path, { headers: headers || null, params: queryParams }).pipe(retry(0));
  }
}
