import {NgModule} from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ApiInterceptor, DEFAULT_TIMEOUT} from "./interceptors/api.interceptor";
import {LayoutModule} from "./layout/layout.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LayoutModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    [{provide: DEFAULT_TIMEOUT, useValue: 30000}]
  ],
})
export class CoreModule {
}
