import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {EChartsOption} from 'echarts';

@Component({
  selector: 'app-pie-e-charts',
  templateUrl: './pie-e-charts.component.html',
  styleUrls: ['./pie-e-charts.component.css'],
})
export class PieEChartsComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() settingsData: any;
  @Input() dataKey: any;
  @Input() labelKey: any;
  @Input() chartName: any;
  @Input() chartDesc: any;
  @Input() chartNameAlign: any = 'center';
  @Input() chartType: any;
  @Input() orientAlign: any = 'vertical';
  @Input() leftAlign: any = 'left';
  @Input() hasSettings = false;
  @Input() secondButtonIcon: any;

  @Output() chartClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondButtonClick: EventEmitter<any> = new EventEmitter<any>();

  chart: EChartsOption = {};
  theme = 'light';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.data) {
      this.createChartData(this.data);
    }
  }

  initChart(inputData: any[]): void {
    this.chart = {
      title: {
        text: this.chartName,
        subtext: this.chartDesc,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: this.orientAlign,
        left: this.leftAlign,
      },
      series: [
        {
          name: this.chartName,
          type: this.chartType,
          radius: '50%',
          data: inputData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  createChartData(baseData: any[]): void {
    const data = [];
    for (const key of baseData) {
      data.push({
        value: key[this.dataKey],
        name: key[this.labelKey],
      });
    }
    this.initChart(data);
  }
}
