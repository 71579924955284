import { Component, Inject, OnInit } from '@angular/core';
import { AlertConfirmModel } from './alert-confirm-model';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.css'],
})
export class AlertConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AlertConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertConfirmModel
  ) {}

  ngOnInit(): void {}

  acceptButton(): void {
    this.dialogRef.close(true);
  }

  declinedButton(): void {
    this.dialogRef.close(false);
  }
}
