<mat-card [class]="cardClass">
  <button mat-icon-button *ngIf="hasSettings" (click)="changeSettings.emit(this.settingsData)">
    <mat-icon>settings</mat-icon>
  </button>

  <button mat-icon-button *ngIf="secondChartBtnIcon" (click)="secondChartButtonClick.emit(null)">
    <mat-icon>{{ secondChartBtnIcon }}</mat-icon>
  </button>

  <div
    echarts
    class="mt-2"
    [options]="chart"
    [theme]="theme"
    (chartClick)="chartClicked.emit($event)"
    [ngStyle]="{ height: height + 'px' }"
  ></div>
</mat-card>
