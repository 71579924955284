import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {EChartsOption} from 'echarts';

@Component({
  selector: 'app-line-echarts',
  templateUrl: './line-echarts.component.html',
  styleUrls: ['./line-echarts.component.css'],
})
export class LineEChartsComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() settingsData: any;
  @Input() dataKey: any;
  @Input() labelKey: any;
  @Input() chartName: any;
  @Input() chartType: any;
  @Input() dataSetLabels: any[] = [];
  @Input() orientAlign: any = 'vertical';
  @Input() leftAlign: any = 'center';
  @Input() hasSettings = false;
  @Input() height: any;
  @Input() cardClass: any;
  @Input() secondChartBtnIcon: any;

  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() chartClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() secondChartButtonClick: EventEmitter<any> = new EventEmitter<any>();

  chart: EChartsOption = {};
  theme = 'light';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.data) {
      this.createChartData(this.data);
    }
  }

  initChart(yData: any, xData: any): void {
    const colors = [];
    for (let i = 0; i < this.dataSetLabels.length; i++) {
      colors.push(this.randomColor('0.7'));
    }
    this.chart = {
      color: colors,
      title: {
        text: this.chartName,
        left: this.leftAlign,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      legend: {
        data: this.dataSetLabels,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: xData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: true,
          },
        },
      ],
      series: yData,
    };
  }

  createChartData(baseData: any[]): void {
    const yData = {
      name: '',
      type: 'line',
      smooth: true,
      showSymbol: true,
      areaStyle: {
        opacity: 0.8,
        color: '#d0f3fabf',
      },
      data: [],
    };
    const yDataTemp: any = [];
    const xData = [];
    for (const key of baseData) {
      xData.push(key[this.labelKey]);
      yDataTemp.push(key[this.dataKey]);
    }
    yData.data = yDataTemp;
    this.initChart(yData, xData);
  }

  randomColor(transparentPercentage: string): string {
    const trans = transparentPercentage;
    let color = 'rgba(';
    for (let i = 0; i < 3; i++) {
      color += Math.floor(Math.random() * 255) + ',';
    }
    color += trans + ')';
    return color;
  }
}
