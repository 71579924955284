import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilderComponent} from './components/form-builder/form-builder.component';
import {TableComponent} from "./components/table/table.component";
import {TableSaveCSVComponent} from "./components/table/table-save-csv/table-save-csv.component";
import {PieEChartsComponent} from "./components/pie-e-charts/pie-e-charts.component";
import {LineEChartsComponent} from "./components/line-echarts/line-echarts.component";
import {BarEchartComponent} from "./components/bar-echart/bar-echart.component";
import {GaugeEchartsComponent} from "./components/gauge-echarts/gauge-echarts.component";
import {TableSaveXLSComponent} from "./components/table/table-save-xls/table-save-xls.component";
import {PageHeaderComponent} from "./components/page-header/page-header.component";
import {AlertConfirmComponent} from "./components/alert-confirm/alert-confirm.component";
import {HorizontalBarEChartComponent} from "./components/horizontal-bar-echart/horizontal-bar-echart.component";
import {ApiService} from "./services/api.service";
import {AlertService} from "./services/alert.service";
import {ReactiveFormsModule} from "@angular/forms";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatToolbarModule} from "@angular/material/toolbar";
import {NgxEchartsModule} from 'ngx-echarts';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTableModule} from "@angular/material/table";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSelectModule} from "@angular/material/select";
import {MatLegacySnackBarModule} from "@angular/material/legacy-snack-bar";


@NgModule({
  declarations: [
    FormBuilderComponent,
    TableComponent,
    TableSaveCSVComponent,
    PieEChartsComponent,
    LineEChartsComponent,
    BarEchartComponent,
    GaugeEchartsComponent,
    TableSaveXLSComponent,
    PageHeaderComponent,
    AlertConfirmComponent,
    HorizontalBarEChartComponent
  ],
  exports: [
    FormBuilderComponent,
    TableComponent,
    TableSaveCSVComponent,
    PieEChartsComponent,
    LineEChartsComponent,
    BarEchartComponent,
    PageHeaderComponent,
    HorizontalBarEChartComponent
  ],
  providers: [ApiService, AlertService],
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MatSnackBarModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTableModule,
    MatMenuModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatSelectModule,
    MatLegacySnackBarModule
  ],
})
export class ShareModule {
}
