import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-horizontal-bar-echart',
  templateUrl: './horizontal-bar-echart.component.html',
  styleUrls: ['./horizontal-bar-echart.component.css'],
})
export class HorizontalBarEChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() settingsData: any;
  @Input() dataKey: any;
  @Input() labelKey: any;
  @Input() chartName: any;
  @Input() chatDescription: any;
  @Input() chartType: any;
  @Input() dataSetLabels: any[] = [];
  @Input() orientAlign: any = 'vertical';
  @Input() leftAlign: any = 'left';
  @Input() hasSettings = false;
  @Input() height: any;

  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() chartClicked: EventEmitter<any> = new EventEmitter<any>();

  chart: EChartsOption = {};
  theme = 'light';

  constructor() {
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.data) {
      this.createChartData(this.data);
    }
  }

  initChart(yData: any, xData: any): void {
    this.chart = {
      title: {
        text: this.chartName,
        subtext: this.chatDescription,
        textAlign: 'left',
      },
      grid: {
        left: 100,
      },
      toolbox: {
        show: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'value',
        name: 'count',
        data: xData,
      },
      yAxis: {
        type: 'category',
        inverse: true,
        axisTick: {
          alignWithLabel: true,
        },
      },
      series: yData,
    };
  }

  createChartData(baseData: any[]): void {
    const yDataTemp: any = [];
    const xData = [];
    for (const key of baseData) {
      xData.push(key[this.labelKey]);
      yDataTemp.push({
        name: key[this.labelKey],
        type: 'bar',
        data: [key[this.dataKey].value],
      });
    }
    this.initChart(yDataTemp, xData);
  }
}
