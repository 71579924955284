<div class="row">
  <div class="col">
    <mat-toolbar color="accent">
      <span>{{ pageTitle }}</span>
      <span class="example-spacer"></span>
      <button mat-raised-button (click)="buttonClicked($event)" class="example-icon" *ngIf="hasButton">
        <mat-icon>{{ buttonIcon }}</mat-icon>
      </button>
    </mat-toolbar>
  </div>
</div>
