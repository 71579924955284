import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {EChartsOption} from 'echarts';

@Component({
  selector: 'app-bar-echart',
  templateUrl: './bar-echart.component.html',
  styleUrls: ['./bar-echart.component.css'],
})
export class BarEchartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() settingsData: any;
  @Input() dataKey: any;
  @Input() labelKey: any;
  @Input() chartName: any;
  @Input() chatDescription: any;
  @Input() chartType: any;
  @Input() dataSetLabels: any[] = [];
  @Input() orientAlign: any = 'vertical';
  @Input() leftAlign: any = 'left';
  @Input() hasSettings = false;
  @Input() height: any;

  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() chartClicked: EventEmitter<any> = new EventEmitter<any>();

  chart: EChartsOption = {};
  theme = 'light';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.data) {
      this.createChartData(this.data);
    }
  }

  initChart(yData: any, xData: any): void {
    this.chart = {
      title: {
        text: this.chartName,
        subtext: this.chatDescription,
        textAlign: 'left',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          data: xData,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: 'Size',
          type: 'bar',
          barWidth: '60%',
          data: yData,
        },
      ],
    };
  }

  createChartData(baseData: any[]): void {
    const yDataTemp: any = [];
    const xData = [];
    for (const key of baseData) {
      xData.push(key[this.labelKey]);
      yDataTemp.push(key[this.dataKey]);
    }
    this.initChart(yDataTemp, xData);
  }
}
