import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-gauge-echarts',
  templateUrl: './gauge-echarts.component.html',
  styleUrls: ['./gauge-echarts.component.css'],
})
export class GaugeEchartsComponent implements OnInit {
  @Input() data: any;
  @Input() settingsData: any;
  @Input() dataKey: any;
  @Input() labelKey: any;
  @Input() chartName: any;
  @Input() chatDescription: any;
  @Input() chartType: any;
  @Input() dataSetLabels: any[] = [];
  @Input() orientAlign: any = 'vertical';
  @Input() leftAlign: any = 'left';
  @Input() hasSettings: boolean = false;
  @Input() height: any;

  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();

  chart: any = {};
  theme = 'light';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.data) {
      this.createChartData(this.data);
    }
  }

  initChart(yData: any, xData: any): void {
    this.chart = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#464646',
            },
          },
          axisLine: {
            lineStyle: {
              width: 40,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: [
            {
              value: 20,
              name: 'Perfect',
              title: {
                offsetCenter: ['0%', '-30%'],
              },
              detail: {
                offsetCenter: ['0%', '-20%'],
              },
            },
            {
              value: 40,
              name: 'Good',
              title: {
                offsetCenter: ['0%', '0%'],
              },
              detail: {
                offsetCenter: ['0%', '10%'],
              },
            },
            {
              value: 60,
              name: 'Commonly',
              title: {
                offsetCenter: ['0%', '30%'],
              },
              detail: {
                offsetCenter: ['0%', '40%'],
              },
            },
          ],
          title: {
            fontSize: 14,
          },
          detail: {
            width: 50,
            height: 14,
            fontSize: 14,
            color: 'auto',
            borderColor: 'auto',
            borderRadius: 20,
            borderWidth: 1,
            formatter: '{value}%',
          },
        },
      ],
    };
  }

  createChartData(baseData: any[]): void {
    const yDataTemp: any = [];
    const xData = [];
    for (const key of baseData) {
      xData.push(key[this.labelKey]);
      yDataTemp.push(key[this.dataKey]);
    }
    this.initChart(yDataTemp, xData);
  }
}
