<div class="overflow-hidden pb-3">
  <h4 class="float-start pt-3">{{ data.title }}</h4>
  <button mat-icon-button class="float-end" (click)="dialogRef.close()" color="warn">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="row">
  <div class="col">
    {{ data.description }}
  </div>
</div>

<div class="row text-center mt-2">
  <div class="col-md-3 col-sm-6 mt-2">
    <button mat-raised-button color="primary" (click)="acceptButton()">
      {{ data.acceptButtonName }}
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
  <div class="col-md-3 col-sm-6 mt-2">
    <button mat-raised-button color="warn" (click)="declinedButton()">
      {{ data.declinedButtonName }}
      <mat-icon>unpublished</mat-icon>
    </button>
  </div>
</div>
