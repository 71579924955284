<mat-toolbar color="primary" class="example-toolbar">
  <button mat-icon-button (click)="snav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <h1 class="example-app-name mt-3">Maple Dating Admin</h1>
</mat-toolbar>

<mat-sidenav-container class="side-nav" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">

  <mat-sidenav #snav [mode]="mobileQuery.matches ? 'side' : 'over'" [fixedInViewport]="mobileQuery.matches"
               fixedTopGap="56" class="h-100" style="width: 250px">

    <mat-nav-list>

      <div *ngFor='let item of menuService.getMenuitem()'>

        <a [routerLink]='[item.link]' [matTooltip]='item.heading' matTooltipPosition='before' mat-list-item
           routerLinkActive="activated-link">
          <mat-icon class='mx-1'>{{item.icon}}</mat-icon>
          <span style='font-size: 12px;' class='mx-3'>{{item.heading}}</span>
        </a>

      </div>

      <a (click)="logout()" [matTooltip]="'Logout'" matTooltipPosition='before' mat-list-item>
        <mat-icon class='mx-1'>logout</mat-icon>
        <span style='font-size: 12px;' class='mx-3'>Logout</span>
      </a>


    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>
