import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from "./core/layout/app-layout/app-layout.component";
import {AuthGuard} from "./core/guards/auth.guard";
import {AuthLayoutComponent} from "./core/layout/auth-layout/auth-layout.component";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    component: AuthLayoutComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    component: AppLayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'question',
    loadChildren: () => import('./modules/question/question.module').then((m) => m.QuestionModule),
    component: AppLayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'client',
    loadChildren: () => import('./modules/client/client.module').then((m) => m.ClientModule),
    component: AppLayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'feedback',
    loadChildren: () => import('./modules/feedback/feedback.module').then((m) => m.FeedbackModule),
    component: AppLayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
