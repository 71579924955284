<div *ngIf="dataSource">
  <form [formGroup]="searchForm">
    <table mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>#</th>

        <td mat-cell *matCellDef="let element; let i = index"></td>
      </ng-container>

      <!-- Value Column Generator -->
      <ng-container *ngFor="let col of columns" [matColumnDef]="col.id" [sticky]="col.sticky">
        <th
          mat-header-cell
          [width]="col.width"
          [style.width]="col.width"
          [style.minWidth]="col.minWidth"
          [ngClass]="{ 'text-center': col.headerAlign === 'center' }"
          *matHeaderCellDef
        >
          <!-- Normal Col Header -->
          <ng-container *ngIf="col && col.type != 'operation'">
            {{ col.name }}
          </ng-container>

          <!-- Operation Col Header -->
          <ng-container *ngIf="col && col.type === 'operation'">
            <div dir="rtl">
              <button mat-icon-button [color]="showSearchBar ? 'primary' : ''" (click)="openSearchBar()">
                <mat-icon class="mat-18">search</mat-icon>
              </button>

              <button mat-icon-button [matMenuTriggerFor]="saveMenu">
                <mat-icon class="mat-18">save</mat-icon>
              </button>
              <mat-menu #saveMenu="matMenu">
                <app-table-save-xls [data]="data" [columns]="columns"></app-table-save-xls>
                <!--                <app-table-save-csv [data]="data" [columns]="columns"></app-table-save-csv>-->
              </mat-menu>
            </div>
          </ng-container>
        </th>

        <td
          mat-cell
          [width]="col.width"
          [style.width]="col.width"
          [style.minWidth]="col.minWidth"
          [ngClass]="{
            'text-center': col.dataAlign === 'center',
            clickable: doubleClickAble
          }"
          (click)="onClick(el)"
          *matCellDef="let el"
        >
          <!-- Price -->
          <!--                    <div *ngIf="col.type == 'price'">-->
          <!--                        {{(col.convert ? col.convert(el[col.id]) : el[col.id]) | price}}-->
          <!--                    </div>-->

          <!-- Number -->
          <div *ngIf="col.type == 'number'">
            {{ (col.convert ? col.convert(el[col.id]) : el[col.id]) | number }}
          </div>

          <!-- Profit -->
          <div *ngIf="col.type == 'profit'" class="profit">
            <div
              fxLayout="row wrap"
              fxLayoutAlign="end center"
              *ngIf="col.convert ? col.convert(el[col.id]) : el[col.id] < 0"
              class="negative"
            >
              {{ (col.convert ? col.convert(el[col.id]) : el[col.id]) | number }}
              <mat-icon class="mat-18">arrow_drop_down</mat-icon>
            </div>

            <div
              fxLayout="row wrap"
              fxLayoutAlign="end center"
              *ngIf="col.convert ? col.convert(el[col.id]) : el[col.id] > 0"
              class="positive"
            >
              {{ (col.convert ? col.convert(el[col.id]) : el[col.id]) | number }}
              <mat-icon class="mat-18">arrow_drop_up</mat-icon>
            </div>
          </div>

          <!-- String -->
          <div *ngIf="col.type == 'string'">
            {{ col.convert ? col.convert(el[col.id]) : el[col.id] }}
          </div>

          <!-- Date -->
          <div *ngIf="col.type == 'date'">
            {{ col.convert ? col.convert(el[col.id]) : el[col.id] }}
          </div>

          <!-- Date -->
          <div *ngIf="col.type == 'image'">
            <img [src]="el[col.id]" alt="profile-photo" class="img-circle w-100 h-100" />
          </div>

          <!-- Operations -->
          <div *ngIf="col.type == 'operation'">
            <div>
              <button
                mat-icon-button
                *ngFor="let op of col.operations"
                [color]="op.color"
                [matTooltip]="op.name"
                (click)="doOperation(el, op)"
              >
                <mat-icon class="mat-18">{{ op.icon }}</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Search Column Generator -->
      <ng-container *ngFor="let col of columns" [matColumnDef]="col.id + '_search'">
        <th mat-header-cell [width]="col.width" [style.minWidth]="col.width" *matHeaderCellDef>
          <div *ngIf="col.search" dir="ltr">
            <!-- text -->
            <mat-form-field *ngIf="col.search.type == 'text'" appearance="outline" style="width: 100%">
              <mat-label>{{ col.name }}</mat-label>
              <input matInput [formControlName]="col.id" />
            </mat-form-field>

            <!-- Select -->
            <mat-form-field *ngIf="col.search.type == 'select'" appearance="outline" style="width: 100%">
              <mat-select [formControlName]="col.id">
                <mat-option [value]=""> همه </mat-option>

                <mat-option *ngFor="let op of col.search.options" [value]="op.value">
                  {{ op.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Date -->
            <mat-form-field *ngIf="col.search.type == 'date'" appearance="outline" style="width: 100%">
              <mat-label>فیلتر تاریخ</mat-label>
              <input matInput [matDatepicker]="picker" [formControlName]="col.id" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </th>
      </ng-container>

      <!--    Header Row    -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="header"></tr>

      <!--    Search Row    -->
      <ng-container *ngIf="hasSearch">
        <tr
          mat-header-row
          *matHeaderRowDef="searchColumns; sticky: true"
          class="header"
          [ngClass]="{ 'd-none': !showSearchBar }"
        ></tr>
      </ng-container>

      <!--    Data Row    -->
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        (click)="selectRow(i)"
        [ngClass]="{ 'table-selected': row.tableSelect }"
      ></tr>
    </table>

    <mat-paginator
      *ngIf="selfPagination"
      class="paginator"
      dir="ltr"
      [pageSize]="selfPaginationPageSize"
      [pageSizeOptions]="selfPaginationItemPerPage"
    >
    </mat-paginator>

    <mat-paginator
      *ngIf="!selfPagination"
      class="paginator"
      dir="ltr"
      (page)="pageChange($event)"
      [pageSize]="selfPaginationPageSize"
      [length]="onlinePaginationLength"
      [pageSizeOptions]="selfPaginationItemPerPage"
    >
    </mat-paginator>
  </form>
</div>
