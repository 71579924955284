import {Injectable} from '@angular/core';

const MENUITEMS = [
  {
    heading: 'Dashboard',
    icon: 'dashboard',
    link: '/dashboard',
    color: '#b6007c',
    permission: null
  },
  {
    heading: 'Q&A',
    icon: 'live_help',
    link: '/question',
    color: '#b6007c',
    permission: null
  },
  {
    heading: 'Clients',
    icon: 'switch_account',
    link: '/client',
    color: '#0096c7',
    permission: null
  },
  {
    heading: 'Feedbacks',
    icon: 'chat_bubble',
    link: '/feedback',
    color: '#0096c7',
    permission: null
  }
];

@Injectable()
export class MenuService {
  constructor() {
  }

  getMenuitem(): any {
    return MENUITEMS;
  }
}
