import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css'],
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle: any;
  @Input() hasButton = true;
  @Input() buttonIcon: string = 'add';
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  buttonClicked($event: any) {
    this.buttonClick.emit(true);
  }
}
