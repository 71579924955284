import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {timeout} from 'rxjs/operators';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  private hasError = false;

  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.totalRequests++;

    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    if (localStorage.getItem('mapleDatingAdminAccessToken')) {
      let token: any;
      const userToken = localStorage.getItem('mapleDatingAdminAccessToken');
      if (userToken != null) {
        token = userToken
      }
      req = req.clone({
        setHeaders: {
          Authorization: `${token}`,
          timeout: `${30000}`
        }
      });
    }

    return next.handle(req).pipe(
      timeout(timeoutValueNumeric),
      catchError((err1) => this.handleError(err1))
    );
  }

  handleError(error: any): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `${error?.error?.message}`;
    } else {
      errorMessage = ` ${error?.error?.message}`;
    }
    return throwError(errorMessage);
  }
}
