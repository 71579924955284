<mat-card>
  <button mat-icon-button *ngIf="hasSettings" (click)="changeSettings.emit(settingsData)">
    <mat-icon>settings</mat-icon>
  </button>

  <button mat-icon-button *ngIf="secondButtonIcon" (click)="secondButtonClick.emit(null)">
    <mat-icon>{{ secondButtonIcon }}</mat-icon>
  </button>

  <div echarts [options]="chart" [theme]="theme" (chartClick)="chartClicked.emit($event)"></div>
</mat-card>
