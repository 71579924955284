<mat-card>
  <button mat-icon-button *ngIf="hasSettings" (click)="changeSettings.emit(this.settingsData)">
    <mat-icon>settings</mat-icon>
  </button>

  <div
    echarts
    [options]="chart"
    [theme]="theme"
    (chartClick)="chartClicked.emit($event)"
    [ngStyle]="{ height: height + 'px' }"
  ></div>
</mat-card>
